import React, { Suspense } from 'react';
// import { AuthContextComponent } from 'src/contexts/auth.context';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { DefaultRoute } from './routes';


const MainPage = React.lazy(() => import('./pages/main/main.page'));
// const ProductPage = React.lazy(() => import('./pages/product/product.page'));
// const OrderPage = React.lazy(() => import('./pages/order/order.page'));
const NotFoundPage = React.lazy(() => import('./pages/misc/404.page'));


function App() {
  return (
    <Router>
      {/* <AuthContextComponent> */}
        <Suspense fallback={<div></div>}>
          <Switch>
            {/* <DefaultRoute path='/' component={MainPage} /> */}
            <DefaultRoute exact path={["/", "/projects", "/events", "/about"]} component={MainPage} />
            {/* <DefaultRoute path='/product' component={ProductPage} /> */}
            {/* <ProtectedRoute path='/order' authenticationPath='/' openModal="profile" component={OrderPage} /> */}
            <DefaultRoute path='/404' component={NotFoundPage} />
          </Switch>
        </Suspense>
      {/* </AuthContextComponent> */}
    </Router>
  );
}

export default App;
