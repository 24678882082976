import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';




export type ProtectedRouteProps = {
  authenticationPath: string
  openModal?: string
} & RouteProps;


export function DefaultRoute({ ...routeProps }: RouteProps) {
  return <Route {...routeProps} />;
};


// export function ProtectedRoute({ authenticationPath, openModal, ...routeProps }: ProtectedRouteProps) {
//   const { userState, websiteState } = React.useContext(AuthContext);
  
//   if (websiteState?.ismaintenance && !userState?.isadmin) {
//     return <MaintenancePage />
//   } else if (userState?.isauth) {
//     return <Route {...routeProps} />;
//   } else {
//     const state = {...((routeProps.location?.state as any) || {}), openModal};
//     return <>
//       {userState?.isloading===false &&
//         // <Redirect to={{ pathname: authenticationPath, state: {from: routeProps.location, openModal} }} />
//         <Redirect to={{ pathname: authenticationPath, state }} />
//       }
//     </>;
//   }
// };
